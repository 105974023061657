@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fcfcfc;
}

.signup-main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #292d3e;
}

.form-wrapper {
  width: 400px;
  max-width: 100%;
  padding: 0px 25px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  background-color: #fff;
  border-radius: 5px;
  margin: 0px 10px;
  font-family: 'Open Sans';
}

.form-header {
  text-align: center;
  font-size: 25px;
  font-family: 'Open Sans';
  font-weight: bold;
  padding-top: 10px;
}

.form-info {
  text-align: center;
  color: #868ba1;
  font-size: 14px;
  font-family: 'Open Sans';
}

.form-footer {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

